<!-- #Developer by Halil Kılıçarslan -->

<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :items="jobInfos"
    :loading="loading"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
  >
    <template v-slot:prepend-item v-if="$attrs.hasOwnProperty('multiple') && jobInfos.length">
      <v-list-item ripple @click="selectAll">
        <v-list-item-action>
          <v-icon :color="Array.isArray(selected) && selected.length > 0 ? 'primary' : ''">{{ _icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="body-2">{{ $t("buttons.select_all") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>
    <template v-if="$attrs.hasOwnProperty('multiple')" v-slot:selection="{ item, index }">
      <div v-if="index === 0">
        {{ index === 0 ? `${selected.length} ${$t("global.selected", [$t("global.jobInfo")])}` : "" }}
      </div>
    </template>
    <template v-else v-slot:selection="{ item, index }">
      {{ item.name }}
    </template>

    <template slot="item" slot-scope="data">
      <template>
        <v-list-item-avatar size="36" :color="data.item.color" class="mr-2">
          <span class="white--text font-weight-bold">{{ data.item.name.substring(0, 2).toUpperCase() }}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="filter-employee-title"
            v-html="data.item.name"
            :class="isEmployeeCountValidate(data.item)"
          />
          <v-list-item-subtitle class="filter-employee-branch" :class="isEmployeeCountValidate(data.item)">
            {{ $t("global.active_employees") }} : {{ data.item.employeeCount }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="filter-employee-unit">{{
            data.item.maxEmployeeCount ? `${$t("global.maxEmployeeCount")} :  ${data.item.maxEmployeeCount}` : null
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
  import * as GQL from "./gql";

  export default {
    inheritAttrs: false,
    name: "JobInfoPicker",
    props: {
      value: [String, Object, Array]
    },
    data: () => ({
      loading: false,
      selected: [],
      jobInfos: []
    }),

    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    },
    computed: {
      isAllSelectedEmployees() {
        return this.selected.length === this.jobInfos.length;
      },
      isLikesSelectedEmployees() {
        return this.selected.length > 0 && !this.isAllSelectedEmployees;
      },
      _icon() {
        if (this.isAllSelectedEmployees) return "mdi-close-box";
        if (this.isLikesSelectedEmployees) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      }
    },
    methods: {
      async fetchJobInfos() {
        this.teams = [];
        this.loading = true;
        await this.$apollo
          .query({
            query: GQL.JOB_INFOS,
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data: { jobInfos }, errors }) => {
            if (!errors) this.jobInfos = jobInfos;
          })
          .catch(e => {
            console.log("e", e);
          })
          .then(() => {
            this.loading = false;
          });
      },
      isEmployeeCountValidate(item) {
        if (item.maxEmployeeCount) {
          return item.employeeCount > item.maxEmployeeCount ? "primary--text" : null;
        }
      },
      selectAll() {
        this.$nextTick(() => {
          if (this.isAllSelectedEmployees) {
            this.selected = [];
          } else {
            this.selected = this.jobInfos.map(item => item.id);
          }
          this.$emit("input", this.selected);
        });
      }
    },

    created() {
      if (this.$attrs.hasOwnProperty("multiple")) {
        this.selected = [];
      } else {
        this.selected = this.value;
      }
      this.fetchJobInfos();
      this.$eventBus.$on("update:jobInfos", () => {
        this.fetchJobInfos();
      });
    }
  };
</script>

<style scoped></style>
