import gql from "graphql-tag";

export const JOB_INFOS = gql`
  query JobInfo {
    jobInfos {
      id
      name
      color
      employeeCount
      maxEmployeeCount
    }
  }
`;
